import PasswordValidator from 'password-validator';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { SetPasswordInput } from '../../Api/Auth/AuthApiClient';
import { Modal } from '../../Components/Modal/Modal';
import { Section } from '../../Components/Section';
import { setToken } from '../../State/Actions/AuthApiActions';
import { addNotification } from '../../State/Actions/NotificationActions';
import { authApiClientSelector, tokenPayloadSelector } from '../../State/Selectors/AuthApiSelectors';
import { Message } from '../Message';

export const SetPassword = () => {
    const dispatch = useDispatch();

    const authApiClient = useSelector(authApiClientSelector);
    const tokenPayload = useSelector(tokenPayloadSelector);

    const [newPassword, setNewPassword] = useState<string | undefined>(undefined);
    const [isSettingPassword, setIsSettingPassword] = useState<boolean>(false);

    const [validationErrors, setValidationErrors] = useState<string[]>([]);

    const [searchParams, setSearchParams] = useSearchParams();

    const submitSetPassword = () => {
        const passwordValidator = new PasswordValidator()
            .is().min(8)
            .has().uppercase()
            .has().lowercase()
            .has().digits();

        const validationResult = passwordValidator.validate(newPassword ?? "", { details: true }) as { message: string }[];

        if (validationResult.length > 0) {
            setValidationErrors(validationResult.map(x => x.message));
        }
        else {
            setValidationErrors([]);

            setIsSettingPassword(true);

            authApiClient.setPassword(new SetPasswordInput({ newPassword: newPassword }))
                .then(issuedToken => {
                    searchParams.delete("token");
                    setSearchParams(searchParams);
                    dispatch(addNotification("Password has been set successfully", "success"));
                    dispatch(setToken(issuedToken.token))
                })
                .catch(() => {
                    setValidationErrors(["Failed to set password"]);
                })
                .finally(() => {
                    setIsSettingPassword(false);
                });
        }
    };

    return <>
        <Section title="Set Password">
            <p className="subtitle">{tokenPayload?.email}</p>
            <div className="field">
                <div className="control">
                    <input
                        className="input"
                        type="password"
                        placeholder="Password"
                        value={newPassword}
                        onChange={event => setNewPassword(event.target.value)} />
                </div>
            </div>
            <div className="field">
                <div className="control">
                    <button className="button is-link" onClick={() => submitSetPassword()}>Submit</button>
                </div>
            </div>
            {validationErrors.map(x => <Message text={x} color="danger" />)}
        </Section>
        <Modal
            isActive={isSettingPassword}
            title="Set password">
            <span className="bulma-loader-mixin"></span> Setting password...
        </Modal>
    </>;
};