import { MediaBox } from '../../Components/MediaBox';
import { BoilerPlateFree, Fast, Managed, Modular, Parallel, RunAnywhere } from '../../Components/Svgs';

export const FeaturesDetail = () => {

    return <div className="features-grid">
        <MediaBox
            title="Modular"
            text="Accelergreat comes with a variety of components including SQL databases and APIs so you can rapidly mock out your environment for integration testing. If you need to test a custom resource then it's easy to extend Accelergreat's base components to support your use case."
            image={<Modular />}
        />
        <MediaBox
            title="Managed"
            text="External dependencies like databases are fully managed throughout the entire lifetime of the test suite execution. This means that you don't need to worry about cleaning up resources when writing your tests. It also allows Accelergreat to intelligently assign available resources to tests during execution based on your configuration."
            image={<Managed />}
        />
        <MediaBox
            title="Parallel"
            text={<>
                With <a href="pricing">Accelergreat Premium</a> your tests will scale out and go even faster with parallel execution.
            </>}
            image={<Parallel />}
        />
        <MediaBox
            title="Boilerplate Free"
            text="Keep your codebase clean and only write code that really matters for the test. Don't worry about provisioning or disposing of resources in your test code - Accelergreat takes care of that for you."
            image={<BoilerPlateFree />}
        />
        <MediaBox
            title="Fast"
            text="Don't waste time waiting, get lightning fast test results. Accelergreat has been designed from the ground up to focus on test execution speed."
            image={<Fast />}
        />
        <MediaBox
            title="Run anywhere"
            text="Run on your local machine and in your CI pipeline. Accelergreat is implemented as a dotnet package with no external tools or installations required."
            image={<RunAnywhere />}
        />
    </div>;
};