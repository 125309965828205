import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { hasJwtExpired } from '../../Functions/hasJwtExpired';
import { tokenPayloadSelector } from '../../State/Selectors/AuthApiSelectors';
import { MyAccount } from '../../Components/Account/MyAccount';
import { SetPassword } from '../../Components/Account/SetPassword';
import { Login } from '../../Components/Account/Login';
import { InvalidToken } from '../../Components/Account/InvalidToken';

export const Account = () => {
    const tokenPayload = useSelector(tokenPayloadSelector);

    const location = useLocation();

    /*useInterval(() => {
        if (tokenPayload && hasJwtExpired(tokenPayload)) {
            dispatch(setToken(undefined));
        }
    }, 30000);*/

    const urlParamsToken = new URLSearchParams(location.search).get("token");

    const isValidToken = tokenPayload && !hasJwtExpired(tokenPayload);

    return <>
        {urlParamsToken && !isValidToken && <InvalidToken />}
        {!urlParamsToken && !isValidToken && <Login />}
        {isValidToken && tokenPayload.scope === "online_account_set_password" && <SetPassword />}
        {isValidToken && tokenPayload.scope === "online_account_access" && <MyAccount />}
    </>;
};