import * as React from 'react';
import MailchimpSubscribe, { NameFormFields } from 'react-mailchimp-subscribe';
import { LabelledInput } from '../../Components/Input';
import { ErrorMessageBox, InfoMessageBox, SuccessMessageBox } from '../../Components/MessageBox';

const postUrl = "https://accelergreat.us20.list-manage.com/subscribe/post";
const uValue = "db03b94901e2fc0491cb45d33"
const idValue = "42651a5e0f";

type Props = {
    status: string | null,
    message: string | Error | null,
    onValidated: (data: NameFormFields) => void
}

const CustomForm = (props: Props) => {

    const [email, setEmail] = React.useState('');
    const [firstName, setFirstName] = React.useState('');
    const [lastName, setLastName] = React.useState('');

    const [isValid, setIsValid] = React.useState(false);

    React.useEffect(() => {

        setIsValid(email.includes("@") && firstName.length > 0 && lastName.length > 0);
    }, [email, firstName, lastName]);

    React.useEffect(() => {
        if (props.status === "success") clearFields();
    }, [props.status]);

    function clearFields() {
        setFirstName('');
        setLastName('');
        setEmail('');
    }

    function handleSubmit(e: any) {
        e.preventDefault();
        if (isValid) {
            props.onValidated({
                EMAIL: email,
                FNAME: firstName,
                LNAME: lastName
            })
        };
    }

    return <div>
        <form onSubmit={handleSubmit}>
            <LabelledInput
                value={email}
                valueChanged={setEmail}
                label="Email address"
            />
            <LabelledInput
                value={firstName}
                valueChanged={setFirstName}
                label="First name"
            />
            <LabelledInput
                value={lastName}
                valueChanged={setLastName}
                label="Last name"
            />

            <input
                className='button is-primary is-pulled-right'
                type='submit'
                value={"submit"}
                disabled={!isValid}
            />
        </form>
        <br />
        <br />
        {props.status === "sending" &&
            <InfoMessageBox>
                Sending...please wait.
            </InfoMessageBox>
        }
        {props.status === "error" &&
            <ErrorMessageBox>
                An error occurred. Please try again later.
            </ErrorMessageBox>
        }
        {props.status === "success" &&
            <SuccessMessageBox>
                Thank you for signing up for the Accelergreat newsletter!
            </SuccessMessageBox>
        }
    </div>;
};

export const SignupForm = () => {

    const url = `${postUrl}?u=${uValue}&id=${idValue}`

    return <div >
        <MailchimpSubscribe<NameFormFields>
            url={url}
            render={(hooks) => {
                return <CustomForm
                    status={hooks.status}
                    message={hooks.message}
                    onValidated={formData => hooks.subscribe(formData)}
                />;
            }}
        />
    </div>;
};