import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { createBrowserHistory } from 'history';
import { Provider } from 'react-redux';
import { BrowserRouter, Routes } from 'react-router-dom';
import { Link, Route, } from 'react-router-dom';
import { Notifications } from './Components/Notifications';
import { NavBar } from './Nav/NavBar';
import { routes } from './Nav/Routes';
import { endUserLicenseAgreement, privacyPolicy } from './Nav/RouteValues';
import { store } from './State/store';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY!);

function App() {
  return (
    <>
      <Provider store={store}>
        <BrowserRouter>
          <Elements stripe={stripePromise}>
            <NavBar />
            <Notifications />
            <Routes>
              {routes.map((route) => <Route
                key={route.path}
                path={route.path}
                element={<route.main />}>
              </Route>)}
            </Routes>
          </Elements>
          <footer className="footer mt-5">
            <div className="content has-text-centered">
              <p>
                <Link to={privacyPolicy}>Privacy Policy</Link> | <Link to={endUserLicenseAgreement}>End User License Agreement (EULA)</Link>
              </p>
              <small>Nanogunn Ltd is a company registered in England and Wales (company number 13703731)</small>
            </div>
          </footer>
        </BrowserRouter>

      </Provider>
    </>
  );
}

export default App;
