import classNames from "classnames";

export type IconProps = {
    /**
    * Material design icon type
   */
    icon: string;
    /** 
     * Text for tooltip
     */
    title?: string;
    className?: string;
}

export const Icon = (props: IconProps) => {

    const className = classNames("mdi mdi-" + props.icon,
        props.className);

    return <span className="icon">
        <i className={className} aria-hidden="true" title={props.title} />
    </span>;
}

Icon.defaultProps = {
    type: "normal"
}