import { getCurrentBrowserFingerPrint } from "@rajesh896/broprint.js";
import { useEffect, useState } from "react";

export default function useVisitorId() {
    const [visitorId, setVisitorId] = useState<string>();

    useEffect(() => {
        getCurrentBrowserFingerPrint().then(setVisitorId);
    }, [setVisitorId]);

    return visitorId;
}