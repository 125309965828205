import * as React from 'react';
import Helmet from 'react-helmet';

const baseTitle = "Accelergreat";

export const withTitle = (
    Component: React.FunctionComponent<{}>,
    subTitle?: string | null) => {

    const title = subTitle
        ? `${subTitle}: ${baseTitle}`
        : baseTitle;

    return () =>
        <>
            <Helmet>
                <title>{title}</title>
            </Helmet>
            <Component />
        </>;
};

export function route(path: string, component: React.FunctionComponent<{}>, subTitle?: string | null) {
    return {
        path: path,
        main: withTitle(component, subTitle),
        exact: true
    };
}
