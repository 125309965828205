type Props = {
    color: "success" | "danger",
    text: string
};

export const Message = (props: Props) => {
    return <article className={`message is-${props.color}`}>
        <div className="message-body">
            {props.text}
        </div>
    </article>
}