import ReactMarkdown from 'react-markdown';

type Props = {
    markdown: string | string[] | undefined
}

export const Markdown = (props: Props) => {

    if (props.markdown === undefined || props.markdown === null) {
        return null;
    }

    const markdown = typeof props.markdown === "string" ? [props.markdown] : props.markdown;

    return <>
        {markdown.map((x, i) => <ReactMarkdown className="mb-4" key={i}>{x}</ReactMarkdown>)}
    </>;
};