import { decodeJwt, JWTPayload } from "jose";
import Cookies from "universal-cookie";
import { Client as AuthApiClient } from "../../Api/Auth/AuthApiClient";
import { hasJwtExpired } from "../../Functions/hasJwtExpired";
import { AuthApiActions, SetTokenAction } from "../Actions/AuthApiActions";

type OnlineAccountTokenPayload = JWTPayload & {
    email: string
};

type OnlineAccountAccessTokenPayload = OnlineAccountTokenPayload & {
    scope: "online_account_access"
};

type OnlineAccountSetPasswordTokenPayload = OnlineAccountTokenPayload & {
    scope: "online_account_set_password"
};

type OnlineAccountTokenPayloads =
    | OnlineAccountAccessTokenPayload
    | OnlineAccountSetPasswordTokenPayload;

type AuthApiState = {
    tokenPayload: OnlineAccountTokenPayloads | undefined,
    authApiClient: AuthApiClient
};

const cookies = new Cookies();
const urlSearchParams = () => new URLSearchParams(window.location.search)

const httpHandler = (token?: string | undefined) => {
    return {
        fetch(input: RequestInfo, init?: RequestInit) {
            init = init ?? {};

            if (token) {
                if (init.headers instanceof Headers) {
                    init.headers!.append("Authorization", "Bearer " + token);
                } else {
                    (init.headers! as any)["Authorization"] = "Bearer " + token;
                }
            }

            return window.fetch(input, init);
        }
    }
};

const loadInitialState = (): AuthApiState => {
    const preStateToken = urlSearchParams().get("token") ?? cookies.get("token") as string | null;

    let tokenToUse: string | undefined = undefined;
    let tokenToUsePayload: OnlineAccountTokenPayloads | undefined = undefined;

    if (preStateToken) {
        const payload = decodeJwt(preStateToken) as OnlineAccountTokenPayloads;

        if (!hasJwtExpired(payload)) {
            tokenToUse = preStateToken;
            tokenToUsePayload = payload;
        }
        else if (payload.scope === "online_account_access") {
            cookies.remove("token");
        }
    }

    return {
        tokenPayload: tokenToUsePayload,
        authApiClient: new AuthApiClient(process.env.REACT_APP_AUTH_API_BASE_URL!, httpHandler(tokenToUse))
    }
};

const reduceSetToken = (state: AuthApiState, action: SetTokenAction): AuthApiState => {
    let tokenPayload: OnlineAccountTokenPayloads | undefined = undefined;

    if (action.token) {
        tokenPayload = decodeJwt(action.token) as OnlineAccountTokenPayloads;

        if (tokenPayload.scope === "online_account_access") {
            cookies.set("token", action.token);
        }
    }
    else {
        cookies.remove("token");
    }

    return {
        ...state,
        tokenPayload: tokenPayload,
        authApiClient: new AuthApiClient(process.env.REACT_APP_AUTH_API_BASE_URL!, httpHandler(action.token))
    };
};

const INITIAL_STATE = loadInitialState();

export const authApiReducer = (state: AuthApiState = INITIAL_STATE, action: AuthApiActions): AuthApiState => {
    switch (action.type) {
        case "SET_TOKEN":
            return reduceSetToken(state, action);
        default:
            return state;
    }
};