import { Testimonial } from "./Testimonial";

export const Testimonials = () => {
    return <>
        <Testimonial
            image="/images/hgem-logo.svg"
            qoute="The team was writing high quality well tested code, but the integration tests were just taking too long to run, we were introduced to Accelergreat, the tests now run in a fraction of the time. Improving the Team's productivity significantly."
            author="Paul Watts"
            jobTitle="Technical Lead"
            company="HGEM"
            websiteURL="https://www.hgem.com/" />
        <Testimonial
            image="/images/miiji-logo.svg"
            qoute="Miiji has used Accelergreat to enable rapid development and prototyping of our brand new cloud-based system. Developers can try out new ideas and test them easily and quickly which gives us more time to focus on our core IP. Every dev team knows the benefits of automated testing and it's awesome that Accelergreat has helped Miiji move with agility while also having the reassurance that code quality and reliability is maintained."
            author="Dr Stephen Hicks"
            jobTitle="Chief Technology Officer"
            company="Miiji"
            websiteURL="https://www.miiji.me/" />
    </>;
}