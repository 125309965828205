import classNames from 'classnames';
import * as React from 'react';

type Props = {
    title: string,
    subtitle?: string,
    className?: string,
    children?: React.ReactNode
}

export const Hero = (props: Props) => {

    const className = classNames("hero", props.className);

    return <section className={className}>
        <div className="hero-body">
            <p className="title">{props.title}</p>
            {props.subtitle !== undefined && <p className="subtitle">{props.subtitle}</p>}
            {props.children !== undefined && props.children}
        </div>
    </section>;
}