import * as React from 'react';
import { Section } from '../../Components/Section';

export const Contact = () => {

    return <>
        <Section title="Contact">
            <p>
                todo
            </p>
        </Section>
    </>;
}