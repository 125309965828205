import { Billing_FAQs, General_FAQs, Licensing_FAQs, Using_FAQs } from "../../Blobs/FAQs";
import { Section } from "../../Components/Section";
import { FAQSection } from "./FAQSection";

export const FAQs = () => {

    return <Section title="Frequently Asked Questions">

        <FAQSection faqs={General_FAQs} title="General" icon="chat-question" />
        <FAQSection faqs={Using_FAQs} title="Using Accelergreat" icon="cog" />
        <FAQSection faqs={Billing_FAQs} title="Billing" icon="cash" />
        <FAQSection faqs={Licensing_FAQs} title="Licensing" icon="license" />

    </Section>;
}