import { MediaBox } from '../../Components/MediaBox';
import { BoilerPlateFree, Fast, Managed, Modular, Parallel, RunAnywhere } from '../../Components/Svgs';

export const FeaturesSummary = () => {

    return <div className="features-grid">
        <MediaBox
            title="Modular"
            text="Need to test with a database? Great. need to test with an API? You got it! Need both? At your service."
            image={<Modular />}
        />
        <MediaBox
            title="Managed"
            text="External dependencies like databases are fully managed throughout the entire lifetime of the test suite execution."
            image={<Managed />}
        />
        <MediaBox
            title="Parallel"
            text="Heard about our ultra fast integration tests? Scale out and go even faster with parallel execution."
            image={<Parallel />}
        />
        <MediaBox
            title="Boilerplate Free"
            text="Keep your codebase clean and only write code that really matters for the test."
            image={<BoilerPlateFree />}
        />
        <MediaBox
            title="Fast"
            text="Don't waste time waiting, get lightning fast test results."
            image={<Fast />}
        />
        <MediaBox
            title="Run anywhere"
            text="Run on your local machine and in your CI pipeline."
            image={<RunAnywhere />}
        />
    </div>;
};