import { NotificationActions, NotificationColor } from "../Actions/NotificationActions";
import { v4 as createGuid } from 'uuid';


type NotificationState = {
    notifications: { id: string, message: string, color: NotificationColor }[]
};

const INITIAL_STATE: NotificationState = {
    notifications: []
};

export const notificationReducer = (state: NotificationState = INITIAL_STATE, action: NotificationActions): NotificationState => {
    switch (action.type) {
        case "ADD_NOTIFICATION":
            return {
                ...state,
                notifications: [
                    ...state.notifications,
                    { id: createGuid(), message: action.message, color: action.color }
                ]
            };
        case "REMOVE_NOTIFICATION":
            return {
                ...state,
                notifications: state.notifications.filter(x =>
                    x.id !== action.id)
            };
        default:
            return state;
    }
};