import { Stripe } from "@stripe/stripe-js";
import { useSelector } from "react-redux";
import { purchaseSubscription } from "../../Functions/stripeFunctions";
import { authApiClientSelector } from "../../State/Selectors/AuthApiSelectors";

type BaseProps = {
    planName: string,
    planNameIntro?: string,
    planPrice: string,
    planPriceIntro: string
}

type FreeProps = BaseProps & {
    type: "free"
};

type PurchaseProps = BaseProps & {
    type: "purchase",
    stripe: Stripe,
    priceId: string,
    planPriceDiscountedFrom?: string,
    visitorId: string | undefined,
    setIsRedirecting: () => void
};

type ContactUsProps = BaseProps & {
    type: "contact-us",
    email: string
};

type Props =
    | FreeProps
    | PurchaseProps
    | ContactUsProps;

function buttonText(props: Props) {
    let result = "";

    switch (props.type) {
        case "free":
            result = "Download now";
            break;
        case "purchase":
            result = "Start free trial";
            break;
        case "contact-us":
            result = "Contact us";
            break;
    }

    return result;
}

export const SubscriptionPlanTile = (props: Props) => {
    const authApiClient = useSelector(authApiClientSelector);

    function handleClick(props: Props) {
        switch (props.type) {
            case "free":
                window.location.href = `https://www.nuget.org/profiles/Nanogunn`;
                break;
            case "purchase":
                props.setIsRedirecting();
                purchaseSubscription(props.stripe, authApiClient, props.priceId, props.visitorId);
                break;
            case "contact-us":
                window.location.href = `mailto:${props.email}?subject=Accelergreat premium subscription custom pricing`;
                break;
        }
    }

    return <article className="tile is-parent is-vertical has-text-centered has-background-primary-light ml-2 mr-2">
        <p className="title">{props.planName}</p>
        <p className="subtitle mb-0">{props.planNameIntro}{!props.planNameIntro && <small>&nbsp;</small>}</p>
        <hr className="mb-3" />
        <p className="is-size-2 mb-0">{props.planPrice} {props.type === "purchase" && props.planPriceDiscountedFrom && <small className="is-size-3"><del className="has-text-grey-light">{props.planPriceDiscountedFrom}</del></small>}</p>
        <p className="is-size-7 mb-0">{props.planPriceIntro}</p>
        <hr />
        <button className="button is-primary" onClick={() => handleClick(props)}>{buttonText(props)}</button>
    </article>;
}