import * as React from 'react';
import { Icon } from './Icon';

type Props = {
    text: React.ReactNode,
    icon: string | React.ReactNode,
    hideTextOnMobile?: boolean
}

export const TextAndIcon = (props: Props) => {

    return <>
        <span className={props.hideTextOnMobile ? "is-hidden-mobile" : ""}>
            {props.text}
        </span>
        {typeof props.icon === "string" && <Icon icon={props.icon} />}
        {typeof props.icon !== "string" && props.icon}
    </>;
};