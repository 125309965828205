import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AccountSubscription } from "../../Api/Auth/AuthApiClient";
import { Modal } from "../Modal/Modal";
import { Section } from "../Section";
import { openInNewTab } from "../../Functions/openInNewTab";
import { authApiClientSelector, tokenPayloadSelector } from "../../State/Selectors/AuthApiSelectors";
import { setToken } from "../../State/Actions/AuthApiActions";
import { Link } from "react-router-dom";
import { pricing } from "../../Nav/RouteValues";

export const MyAccount = () => {
    const dispatch = useDispatch();
    const authApiClient = useSelector(authApiClientSelector);
    const tokenPayload = useSelector(tokenPayloadSelector);

    const [accountSubscriptions, setAccountSubscriptions] = useState<AccountSubscription[] | undefined>(undefined);

    const [isAuthorizingBillingPortal, setIsAuthorizingBillingPortal] = useState<boolean>(false);

    const manageSubsription = (subscriptionId: string) => {
        setIsAuthorizingBillingPortal(true);

        authApiClient.authorizeBillingPortal(subscriptionId)
            .then(authorizedBillingPortal => openInNewTab(authorizedBillingPortal.url!))
            .finally(() => setIsAuthorizingBillingPortal(false));
    };

    useEffect(() => {
        if (!accountSubscriptions) {
            authApiClient.subscriptions().then(setAccountSubscriptions);
        }
    }, [accountSubscriptions, setAccountSubscriptions]);

    return <>
        <div className="columns is-mobile">
            <div className="column">
                <Section title="Account">
                    <p className="subtitle">{tokenPayload?.email}</p>
                </Section>
            </div>
            <div className="column is-one-fifth">
                <div className="is-flex is-flex-direction-row-reverse section">
                    <button className="button  is-light" onClick={() => dispatch(setToken(undefined))}>Logout</button>
                </div>
            </div>
        </div>
        <Section title="Your subscriptions">
            {accountSubscriptions && <>
                <table className="table is-fluid container">
                    <thead>
                        <tr>
                            <th>Plan</th>
                            <th>Pricing</th>
                            <th>Status</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {accountSubscriptions.map(accountSubscription => <tr key={accountSubscription.subscriptionId!}>
                            <td>{accountSubscription.productName}</td>
                            <td>{accountSubscription.term}</td>
                            <td>{accountSubscription.nextTerm}</td>
                            <td>{accountSubscription.subscriptionId &&
                                <button
                                    className="button is-link is-small"
                                    onClick={() => manageSubsription(accountSubscription.subscriptionId!)}>
                                    Manage
                                </button>}
                            </td>
                        </tr>)}
                    </tbody>
                </table>
            </>}
            <p>You can purchase a new subscription from the <Link to={pricing}>pricing</Link> page.</p>
        </Section>
        <Modal
            isActive={accountSubscriptions === undefined}
            title="Loading subscriptions">
            <span className="bulma-loader-mixin"></span> Retrieving data...
        </Modal>
        <Modal
            isActive={isAuthorizingBillingPortal}
            title="Manage subscription">
            <span className="bulma-loader-mixin"></span> Redirecting to Stripe...
        </Modal>
    </>;
}