import { Section } from '../../Components/Section';
import { FeaturesDetail } from './FeaturesDetail';

export const Features = () => {

    return <>
        <Section title="Features">
            <FeaturesDetail />
        </Section>
    </>;
}