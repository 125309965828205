import { useState } from "react";
import { useSelector } from "react-redux";
import { EmailAddressInput } from "../../Api/Auth/AuthApiClient";
import { authApiClientSelector } from "../../State/Selectors/AuthApiSelectors";
import { Message } from "../Message";
import { Modal } from "../Modal/Modal";
import { Section } from "../Section"

export const RequestSetPassword = () => {
    const authApiClient = useSelector(authApiClientSelector);

    const [emailAddress, setEmailAddress] = useState<string | undefined>(undefined);
    const [requestSetPasswordState, setRequestSetPasswordState] = useState<"requesting" | "requested" | undefined>(undefined);

    const submitRequestSetPassword = () => {
        if (emailAddress) {
            setRequestSetPasswordState("requesting");

            authApiClient.requestSetPasswordEmail(new EmailAddressInput({ emailAddress: emailAddress }))
                .finally(() => {
                    setEmailAddress(undefined);
                    setRequestSetPasswordState("requested");
                });
        }
    };

    return <>
        <Section title="First time logging in?">
            <p>If this is your first time logging in or you have forgotten your password, you will need to request a set password email.</p>
            <div className="field">
                <div className="control">
                    <input
                        className="input"
                        type="email"
                        placeholder="Email address"
                        value={emailAddress}
                        onChange={event => setEmailAddress(event.target.value)} />
                </div>
            </div>
            <div className="field">
                <div className="control">
                    <button className="button is-primary" onClick={() => submitRequestSetPassword()}>Submit</button>
                </div>
            </div>
            {requestSetPasswordState === "requested" && <Message text="An email has been sent." color="success" />}
        </Section>
        <Modal
            isActive={requestSetPasswordState === "requesting"}
            title="Set password">
            <span className="bulma-loader-mixin"></span> Requesting set password email...
        </Modal>
    </>;
}