type Props = {
    image: string,
    qoute: string,
    author: string,
    jobTitle: string,
    company: string,
    websiteURL: string
};

export const Testimonial = (props: Props) => {
    return <>
        <div className="columns is-hidden-mobile">
            <div className="column is-one-quarter is-flex is-flex-direction-row is-justify-content-end">
                <img src={props.image} style={{ maxHeight: "125px" }} />
            </div>
            <div className="column ml-6">
                <blockquote className="is-size-4 has-text-weight-medium">
                    “{props.qoute}”
                </blockquote>
                <figcaption className="is-size-5 mt-2">— {props.author}, {props.jobTitle}, <a href={props.websiteURL} target="_blank">{props.company}</a></figcaption>
            </div>
        </div>
        <div className="columns is-hidden-tablet">
            <div className="column is-one-quarter is-flex is-flex-direction-row is-justify-content-center">
                <img src={props.image} style={{ maxHeight: "125px" }} />
            </div>
            <div className="column">
                <blockquote className="is-size-4 has-text-weight-medium">
                    “{props.qoute}”
                </blockquote>
                <figcaption className="is-size-5 mt-2">—{props.author}, {props.jobTitle}, <a href={props.websiteURL} target="_blank">{props.company}</a></figcaption>
            </div>
        </div>
    </>
}