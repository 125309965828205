import { Person } from '../../Components/About/Person';
import { Section } from '../../Components/Section';

export const About = () => {

    return <>
        <Section title="Introducing Accelergreat">
            <div className="content">
                <p>
                    Historically, integration tests have been considered hard to write, slow to write and slow to run. Accelergreat aims to solve all three of these problems. We believe that integration tests should be easy to write, fast to write and fast to run.
                </p>
                <p>
                    Accelergreat is a dotnet / C# NuGet package which achieves this with plans to be implemented in more languages in the future.
                </p>
                <p>
                    Accelergreat is developed in the UK by Nanogunn Ltd (company number 13703731).
                </p>
            </div>
        </Section>
        <Section title="People">
            <div className="content">
                <div className="columns">
                    <div className="column">
                        <Person
                            image="/images/people/bradley.jpg"
                            name="Bradley Gunn"
                            title="Software Engineering & Project Lead"
                            bio=" I wanted to create a framework that removes the complexity behind writing integration tests whilst also significantly reducing the execution time. Creating an environment that integration tests being the default option makes sense."
                            linkedIn="https://www.linkedin.com/in/bradleygunn/"
                        />
                    </div>
                    <div className="column">
                        <Person
                            image="/images/people/andy.jpg"
                            name="Andy Longhurst"
                            title="Software Engineering & Business Strategy"
                            bio="I love the rapid prototyping stage of tech projects and hate it when I get bogged down in writing boilerplate code or doing repetetive manual tests. I wanted to help develop a framework that let me test my ideas without slowing me down. Accelergreat also removes the dread around writing integration tests so as a developer I'm happier to test earlier rather than leaving them all to the end."
                            linkedIn="https://www.linkedin.com/in/andy-longhurst-3398152a/"
                        />
                    </div>
                </div>
            </div>
        </Section>
    </>;
}