export type FAQ = {
    question: string,
    content: string[]
}

function faq(question: string, ...content: string[]): FAQ {
    return {
        question, content
    }
}

export const General_FAQs = [
    faq(
        "How is accelergreat different from other testing frameworks such as XUnit and MSTest?",
        "Accelergreat is an integration test framework as opposed to a test running tool. Using Accelergreat by itself won't expose any tests to be executed and you will still need to use a framework to run the tests.",
        "Currently there is an [XUnit Accelergreat package](https://www.nuget.org/packages/Accelergreat.Xunit/) with more test frameworks to be supported in time."
    ),
    faq(
        "How can I get in touch to ask for help?",
        "Please email [mail@accelergreat.net](mailto:mail@accelergreat.net)."
    )
];

export const Using_FAQs = [
    faq(
        "Can I use accelergreat on a build server?",
        "Yes! Accelergreat is delivered as a series if NuGet packages and so can run anywhere where the dotnet SDK is installed. Bear in mind that some components (for example SQL Server) may require a specific operating system to work."
    ),
    faq(
        "Do I need Visual Studio to use Accelergreat?",
        "No - all you need is the dotnet SDK. There are no specific tools or plugins needed to use Accelergreat."
    ),
    faq(
        "Does Accelergreat work with other dev tools such as Resharper?",
        "Accelergreat tests are exposed using existing test frameworks such as XUnit and so any tools which can execute tests will be able to run them.",
        "The tests are written in C# and are usually part of the codebase being tested and so Resharper can help with refactoring, linting etc."
    ),
];

export const Billing_FAQs = [
    faq(
        "How do I cancel my subscription?",
        "1 - Navigate to [https://accelergreat.net/account](https://accelergreat.net/account) and login.",
        "2 - Click **manage** on the subscription you wish to cancel (this will take you through to the Stripe billing portal).",
        "3 - Click **cancel plan**. After you have confirmed, the subscription will be valid until the end of the current billing period.",
    ),
    faq(
        "How do I upgrade or downgrade my subscription?",
        "1 - Navigate to [https://accelergreat.net/account](https://accelergreat.net/account) and login.",
        "2 - Click **manage** on the subscription you wish to cancel (this will take you through to the Stripe billing portal).",
        "3 - Click **update plan** and pick the new plan. After you have confirmed, your subscription will be updated with immediate effect.",
    ),
    faq(
        "What payment cards do you accept?",
        "We use [Stripe](https://stripe.com) for payment processing. The list of supported card brands can be found [here](https://stripe.com/docs/payments/cards/supported-card-brands)."
    )
];

export const Licensing_FAQs = [
    faq(
        "Can I use my personal license for commercial development?",
        "Yes, you can use your license for any kind of development.",
        "However, only you may use your personal license; you may not share it with others.",
        "Also your company cannot pay, reimburse, or in any way finance your personal license."
    ),
    faq(
        "How do you deliver licenses?",
        "After an order has been successfully processed, a license details email is sent to the email address that was specified during checkout."
    )
];