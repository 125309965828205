import * as React from 'react';

type Props = {
    title: React.ReactNode,
    id?: string | undefined,
    children: React.ReactNode
}

export const Section = (props: Props) => {

    return <section className="section" id={props.id}>
        <h2 className="title">{props.title}</h2>
        {props.children}
    </section>;
}