import { Stripe } from "@stripe/stripe-js";
import { CheckoutInput, Client } from "../Api/Auth/AuthApiClient";

export const purchaseSubscription = async (stripe: Stripe, apiClient: Client, priceId: string, visitorId: string | undefined) => {
    const createdCheckoutSession = await apiClient.checkout(new CheckoutInput({
        priceId: priceId,
        successUrl: process.env.REACT_APP_STRIPE_SUCCESS_URL!,
        cancelUrl: process.env.REACT_APP_STRIPE_CANCEL_URL!,
        visitorId: visitorId
    }));

    await stripe.redirectToCheckout({sessionId: createdCheckoutSession.checkoutSessionId!});
};