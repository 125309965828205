type Props = {
    image: string,
    name: string,
    title: string,
    bio: string,
    linkedIn: string
};

export const Person = (props: Props) => {
    return <div className="card" style={{ maxWidth: "640px" }}>
        <div className="card-content">
            <q className="is-size-5">
                {props.bio}
            </q>
        </div>
        <div className="card-content">
            <div className="media">
                <div className="media-left">
                    <figure className="image is-96x96">
                        <img src={props.image} alt="Placeholder image" />
                    </figure>
                </div>
                <div className="media-content">
                    <p className="title is-4">{props.name}</p>
                    <p className="subtitle is-6">{props.title}</p>
                    <p><a href={props.linkedIn}>LinkedIn</a></p>
                </div>
            </div>
        </div>
    </div>
};