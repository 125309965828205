import * as React from 'react';
import { Section } from '../../Components/Section';

export const PrivacyPolicy = () => {

    return <>
        <Section title="Privacy Policy">
            <div className="content">
                <p>
                    Download the privacy policy from <a href="https://cdn.accelergreat.net/legal/PRIVACY_POLICY_Accelergreat.txt">https://cdn.accelergreat.net/legal/PRIVACY_POLICY_Accelergreat.txt</a>.
                </p>
            </div>
        </Section>
    </>;
}