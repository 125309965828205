export const GettingStartedIntro = () => {

    return <>
        <ol>
            <li>
                Add <a href="https://www.nuget.org/packages/" target="_blank">Accelergreat dotnet packages</a> into your test project
            </li>
            <li>
                Add components
            </li>
            <li>
                Add a test startup class
            </li>
            <li>
                Write your tests
            </li>
        </ol>
        <p className='mt-2'>Read the <a href="https://docs.accelergreat.net/" target="_blank">getting started guide</a> to learn more</p>
    </>;
};