import { useStripe } from "@stripe/react-stripe-js"
import { useState } from "react";
import { Modal } from "../Modal/Modal";
import { Section } from "../Section";
import { SubscriptionPlanTile } from "./SubscriptionPlanTile";
import useVisitorId from "../../State/Hooks/useVisitorId";

export const Subscrptions = () => {
    const stripe = useStripe();

    const [pricing, setPricing] = useState<"monthly" | "annual">("monthly");
    const [isRedirecting, setIsRedirecting] = useState<boolean>(false);
    const visitorId = useVisitorId();

    return <Section title="Plans" id="premium-pricing">
        <p className="is-size-5 mb-6">Find the plan that works for you</p>
        <div className="tabs is-toggle">
            <ul>
                <li className={pricing === "monthly" ? "is-active" : ""}>
                    <a onClick={() => setPricing("monthly")}>
                        <span>Monthly</span>
                    </a>
                </li>
                <li className={pricing === "annual" ? "is-active" : ""}>
                    <a onClick={() => setPricing("annual")}>
                        <span>Annual <span className="tag is-success">Save 15%</span></span>
                    </a>
                </li>
            </ul>
        </div>
        <div className="tile is-ancestor">
            <SubscriptionPlanTile
                    type="free"
                    planName="Free"
                    planPrice="£0"
                    planPriceIntro="forever" />
            {pricing === "monthly" && <>
                <SubscriptionPlanTile
                    type="purchase"
                    planName="Developer"
                    planPrice="£10"
                    planPriceIntro="per month"
                    stripe={stripe!}
                    priceId={`${process.env.REACT_APP_INDIVIDUAL_MONTHLY_STRIPE_PRICE_ID!}`}
                    setIsRedirecting={() => setIsRedirecting(true)}
                    visitorId={visitorId} />
                <SubscriptionPlanTile
                    type="purchase"
                    planName="Small team"
                    planNameIntro="Up to 10 developers"
                    planPrice="£50"
                    planPriceIntro="per month"
                    stripe={stripe!}
                    priceId={`${process.env.REACT_APP_SMALL_TEAM_MONTHLY_STRIPE_PRICE_ID!}`}
                    setIsRedirecting={() => setIsRedirecting(true)}
                    visitorId={visitorId} />
            </>}
            {pricing === "annual" && <>
                <SubscriptionPlanTile
                    type="purchase"
                    planName="Developer"
                    planPrice="£100"
                    planPriceDiscountedFrom="£120"
                    planPriceIntro="per year"
                    stripe={stripe!}
                    priceId={`${process.env.REACT_APP_INDIVIDUAL_ANNUAL_STRIPE_PRICE_ID!}`}
                    setIsRedirecting={() => setIsRedirecting(true)}
                    visitorId={visitorId} />
                <SubscriptionPlanTile
                    type="purchase"
                    planName="Small team"
                    planNameIntro="Up to 10 developers"
                    planPrice="£500"
                    planPriceDiscountedFrom="£600"
                    planPriceIntro="per year"
                    stripe={stripe!}
                    priceId={`${process.env.REACT_APP_SMALL_TEAM_ANNUAL_STRIPE_PRICE_ID!}`}
                    setIsRedirecting={() => setIsRedirecting(true)}
                    visitorId={visitorId} />
            </>}
            <SubscriptionPlanTile
                type="contact-us"
                planName="Custom"
                planPrice="Let's talk"
                planPriceIntro="Request custom pricing to suit your business"
                email="mail@accelergreat.net" />
        </div>
        <Modal
            isActive={isRedirecting}
            title="Purchase subscription">
            <span className="bulma-loader-mixin"></span> Redirecting to Stripe...
        </Modal>
    </Section>;
}