import * as React from "react";
import { ReactNode } from "react";
import { BulmaTypes } from "./BulmaTypes";

type Props = {
    type: BulmaTypes,
    className?: string | undefined
}

type MessageBoxProps = Props & {
    children: ReactNode | undefined
}

type ComposedProps = {
    children: ReactNode | undefined
}

export const SuccessMessageBox = (props: ComposedProps) => {
    return <MessageBox {...props} type="success" />;
};

export const WarningMessageBox = (props: ComposedProps) => {
    return <MessageBox {...props} type="warning" />;
};

export const ErrorMessageBox = (props: ComposedProps) => {
    return <MessageBox {...props} type="danger" />;
};

export const InfoMessageBox = (props: ComposedProps) => {
    return <MessageBox {...props} type="info" />;
};

const MessageBox = (props: MessageBoxProps) => {

    return <article className={`message is-${props.type} ${props.className ? props.className : ""}`}>
        <div className="message-body">
            {props.children}
        </div>
    </article>
};

MessageBox.defaultProps = {
    style: {}
};