import { HomeBanner } from '../../Components/Home/HomeBanner';
import { PlanComparison } from '../../Components/Pricing/PlanComparison';
import { Subscrptions } from '../../Components/Pricing/Subscriptions';
import { Section } from '../../Components/Section';
import { Testimonials } from '../../Components/Testimonials';
import { FeaturesSummary } from './FeaturesSummary';
import { GettingStartedIntro } from './GettingStartedInto';

export const Home = () => {

    return <>
        <HomeBanner />
        <Section title="Features">
            <FeaturesSummary />
        </Section>
        <Section title="Get started in minutes">
            <GettingStartedIntro />
        </Section>
        <Section title="Testimonials">
            <Testimonials />
        </Section>
        <PlanComparison />
        <Subscrptions />
    </>;
}