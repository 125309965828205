import { FAQ } from "../../Blobs/FAQs";
import { Markdown } from "../../Components/Markdown";
import { Section } from "../../Components/Section";
import { TextAndIcon } from "../../Components/TextAndIcon";

type Props = {
    title: string,
    icon: string,
    faqs: FAQ[]
}

type FAQProps = {
    faq: FAQ
}

const FAQRender = (props: FAQProps) => {

    return <>
        <p className="has-text-weight-semibold">{props.faq.question}</p>
        <Markdown markdown={props.faq.content} />
    </>;
}

export const FAQSection = (props: Props) => {

    return <Section title={<TextAndIcon text={props.title} icon={props.icon} />}>
        {props.faqs.map(x => <FAQRender key={x.question} faq={x} />)}
    </Section>;
}