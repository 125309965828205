import { Hero } from '../../Components/Hero';

export const CheckoutSuccess = () => {

    return <>
        <Hero title="Thank you for purchasing an Accelergreat premium subscription" className="is-info is-medium">
            <p>
                Your new license details have been emailed to you.
            </p>
        </Hero>
    </>;
};