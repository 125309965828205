import { Section } from '../../Components/Section';

export const EndUserLicenseAgreement = () => {

    return <>
        <Section title="End User License Agreement (EULA)">
            <div className="content">
                <p>
                    Download the EULA from <a href="https://cdn.accelergreat.net/legal/END_USER_LICENSE_AGREEMENT_Accelergreat.txt">https://cdn.accelergreat.net/legal/END_USER_LICENSE_AGREEMENT_Accelergreat.txt</a>.
                </p>
            </div>
        </Section>
    </>;
}