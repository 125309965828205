import { Section } from '../../Components/Section';
import { SignupForm } from './SignupForm';

export const Register = () => {

    return <>
        <Section title="Register Interest">
            <p className="content">
                Accelergreat is here! To receive updates please enter your email addres below.
            </p>
            <SignupForm />
        </Section>
    </>;
}