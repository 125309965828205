import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { LoginInput } from '../../Api/Auth/AuthApiClient';
import { Modal } from '../Modal/Modal';
import { Section } from '../Section';
import { setToken } from '../../State/Actions/AuthApiActions';
import { authApiClientSelector } from '../../State/Selectors/AuthApiSelectors';
import { RequestSetPassword } from './RequestSetPassword';
import { Message } from '../Message';

export const Login = () => {
    const dispatch = useDispatch();

    const authApiClient = useSelector(authApiClientSelector);

    const [emailAddress, setEmailAddress] = useState<string | undefined>(undefined);
    const [password, setPassword] = useState<string | undefined>(undefined);
    const [loginState, setLogInState] = useState<"requesting" | "success" | "failed" | undefined>(undefined);

    const submitLogin = () => {
        setLogInState("requesting");

        authApiClient.login(new LoginInput({ emailAddress: emailAddress, password: password }))
            .then(issuedToken => {
                setLogInState("success");
                setEmailAddress(undefined);
                setPassword(undefined);
                dispatch(setToken(issuedToken.token));
            })
            .catch(() => setLogInState("failed"));
    };

    return <>
        <Section title="Login">
            <div className="field">
                <div className="control">
                    <input
                        className="input"
                        type="email"
                        placeholder="Email address"
                        value={emailAddress}
                        onChange={event => setEmailAddress(event.target.value)} />
                </div>
            </div>
            <div className="field">
                <div className="control">
                    <input
                        className="input"
                        type="password"
                        placeholder="Password"
                        value={password}
                        onChange={event => setPassword(event.target.value)} />
                </div>
            </div>
            <div className="field">
                <div className="control">
                    <button className="button is-primary" onClick={() => submitLogin()}>Submit</button>
                </div>
            </div>
            {loginState === "failed" && <Message text="Invalid credentials." color="danger" />}
        </Section>
        <RequestSetPassword />
        <Modal
            isActive={loginState === "requesting"}
            title="Login">
            <span className="bulma-loader-mixin"></span> Logging in...
        </Modal>
    </>;
};