export type SetTokenAction = {
    type: "SET_TOKEN",
    token: string | undefined
};

export type AuthApiActions =
    | SetTokenAction;

export const setToken = (token: string | undefined): SetTokenAction => {
    return {
        type: "SET_TOKEN",
        token: token
    };
};