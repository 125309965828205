import * as React from 'react';

type Props = {
    title: React.ReactNode,
    text: React.ReactNode,
    image: React.ReactNode
}

export const MediaBox = (props: Props) => {

    return <div className="box">
        <article className="media">
            <div className="media-left">
                <figure className="image is-64x64">
                    {props.image}
                </figure>
            </div>
            <div className="media-content">
                <div className="content">
                    <p>
                        <strong>{props.title}</strong>
                        <br />
                        {props.text}
                    </p>
                </div>
            </div>
        </article>
    </div>;
}