import * as React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { Icon } from '../Components/Icon';
import { tokenPayloadSelector } from '../State/Selectors/AuthApiSelectors';
import { about, account, contact, documentation, faqs, features, home, pricing, report } from './RouteValues';


// Not currently needed, but will be soon
const AdditionalNav = () => {
    return <div className="navbar-item has-dropdown is-hoverable">
        <a className="navbar-link">
            More
        </a>

        <div className="navbar-dropdown">
            <Link className="navbar-item" to={contact}>
                Contact
            </Link>
            <hr className="navbar-divider" />
            <Link className="navbar-item" to={report}>
                Report an issue
            </Link>
        </div>
    </div>;
};

export const NavBar = () => {

    const [isBurgerOpen, setIsBurgerOpen] = React.useState(false);

    const tokenPayload = useSelector(tokenPayloadSelector);

    return <nav className="navbar" role="navigation" aria-label="main navigation">
        <div className="navbar-brand">
            <Link className="navbar-item" to="/">
                {/* <h1 className="title">ACCELERGREAT</h1> */}
                <img src="/images/accelergreat-logo.svg" title='Accelergreat Logo' />
            </Link>

            <a role="button" className={`navbar-burger ${isBurgerOpen ? "is-active" : ""}`} aria-label="menu" aria-expanded="false" data-target="accelergreatNav" onClick={_ => setIsBurgerOpen(!isBurgerOpen)}>
                <span aria-hidden="true"></span>
                <span aria-hidden="true"></span>
                <span aria-hidden="true"></span>
            </a>
        </div>

        <div id="accelergreatNav" className={`navbar-menu ${isBurgerOpen ? "is-active" : ""}`}>
            <div className="navbar-start">
                <Link className="navbar-item" to={home}>
                    Home
                </Link>

                <Link className="navbar-item" to={features}>
                    Features
                </Link>

                <a className="navbar-item" href={documentation}>
                    Documentation
                </a>

                <Link className="navbar-item" to={pricing}>
                    Pricing
                </Link>

                <Link className="navbar-item" to={faqs}>
                    FAQs
                </Link>

                <Link className="navbar-item" to={about}>
                    About
                </Link>

                {/* <AdditionalNav /> */}
            </div>

            <div className="navbar-end">
                <div className="navbar-item">
                    <div className="buttons">
                        <a className="button has-image" href="https://www.nuget.org/profiles/Nanogunn" >
                            <img src="images/nuget.svg" title="Accelergreat on NuGet" />
                            NuGet
                        </a>
                        <a className="button" href="https://github.com/Accelergreat" >
                            <Icon icon="github" title="Accelergreat on GitHub" />
                            Samples
                        </a>
                        <Link to={account} className="button is-primary">
                            <strong>{tokenPayload && tokenPayload.scope === "online_account_access" ? "My Account" : "Login"}</strong>
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    </nav>;
}