import { About } from '../Pages/About/Contact';
import { Account } from '../Pages/Account/Account';
import { CheckoutSuccess } from '../Pages/CheckoutSuccess/CheckoutSuccess';
import { Contact } from '../Pages/Contact/Contact';
import { Documentation } from '../Pages/Documentation/Documentation';
import { FAQs } from '../Pages/FAQs/FAQs';
import { Features } from '../Pages/Features/Features';
import { Home } from '../Pages/Home/Home';
import { EndUserLicenseAgreement } from '../Pages/Legal/EndUserLicenseAgreement';
import { PrivacyPolicy } from '../Pages/Legal/PrivacyPolicy';
import { Pricing } from '../Pages/Pricing/Pricing';
import { Register } from '../Pages/Register/Register';
import { Report } from '../Pages/Report/Report';
import { route } from './RouteHelpers';
import { about, account, checkoutSuccess, contact, documentation, endUserLicenseAgreement, faqs, features, home, pricing, privacyPolicy, register, report } from './RouteValues';

export const routes = [

    route(home, Home, "Ultra Fast Integration Tests"),
    route(features, Features, "Features"),
    route(documentation, Documentation, "Documentation"),
    route(contact, Contact, "Contact"),
    route(register, Register, "Register"),
    route(report, Report, "Report"),
    route(about, About, "About"),
    route(privacyPolicy, PrivacyPolicy, "Privacy Policy"),
    route(endUserLicenseAgreement, EndUserLicenseAgreement, "EULA"),
    route(checkoutSuccess, CheckoutSuccess, "Checkout Success"),
    route(pricing, Pricing, "Pricing"),
    route(account, Account, "Account"),
    route(faqs, FAQs, "FAQs"),
];