import { useDispatch, useSelector } from "react-redux";
import { removeNotification } from "../State/Actions/NotificationActions";
import { notificationsSelector } from "../State/Selectors/NotificationSelectors";

export const Notifications = () => {
    const dispatch = useDispatch();

    const notifications = useSelector(notificationsSelector);

    return <>
        {notifications.length > 0 && <div className="m-2 columns">
            <div className="column is-three-fifths is-offset-one-fifth">
                {notifications.map(x => <div className={`notification is-${x.color} is-light`} key={x.id}>
                    <button className="delete" onClick={() => dispatch(removeNotification(x.id))}></button>
                    {x.message}
                </div>)}
            </div>
        </div>}
    </>;
};