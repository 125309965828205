type Props = {
    isActive: boolean,
    title?: string | undefined,
    children: React.ReactNode
};

export const Modal = (props: Props) => {
    return <div className={`modal ${props.isActive ? "is-active" : ""}`}>
        <div className="modal-background"></div>
        <div className="modal-card">
            {props.title && <header className="modal-card-head">
                <p className="modal-card-title">{props.title}</p>
            </header>}
            <section className="modal-card-body">
                {props.children}
            </section>
        </div>
    </div>;
}