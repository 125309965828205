import * as React from 'react';

type Props = {
    value: string | undefined,
    placeholder?: string,
    size?: number,
    isDisabled?: boolean,
    valueChanged: (value: string) => any,
    onSubmit?: () => any
}

export const Input = (props: Props) => {

    return <input
        className='input'
        {...props}
        disabled={props.isDisabled}
        onChange={e => props.valueChanged(e.target.value)}
    />
}

type LabelledProps = Props & {
    label: string
}

export const LabelledInput = (props: LabelledProps) => {
    return <div className="field">
        <label className="label">{props.label}</label>
        <div className="control">
            <Input {...props} />
        </div>
    </div>
}