export const home = "/";
export const about = "/about";
export const features = "/features";
export const documentation = "https://docs.accelergreat.net";
export const contact = "/contact";
export const register = "/register";
export const report = "/report";
export const privacyPolicy = "/privacy-policy";
export const endUserLicenseAgreement = "/eula";
export const checkoutSuccess = "/checkout-success";
export const pricing = "/pricing";
export const account = "/account";
export const faqs = "/faqs";
