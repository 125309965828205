import { Section } from "../Section";
import { Check } from "../Svgs";

export const PlanComparison = () => {
    return <Section title="Plans">
        <div className="columns">
            <div className="column">
                <p className="title">Free</p>
                <p>Accelergreat is free to download and use in your codebase, forever.</p>
                <p className="mt-5"><Check /><span className="ml-1">Modular components</span></p>
                <p className="mt-2"><Check /><span className="ml-1">Managed dependencies</span></p>
                <p className="mt-2"><Check /><span className="ml-1">Sequential test execution</span></p>
                <p className="mt-2"><Check /><span className="ml-1 has-tooltip-right" data-tooltip="Run on your local machine and in your CI pipeline.">Run anywhere</span></p>
                <p className="mt-2"><Check /><span className="ml-1 has-tooltip-right">Reduce test boilerplate code</span></p>
            </div>
            <div className="column">
                <p className="title">Premium</p>
                <p>Go pro with the best premium features.</p>
                <p className="mt-5"><Check /><span className="ml-1">All free to use features</span></p>
                <p className="mt-2"><Check /><span className="ml-1">Parallel test execution</span></p>
                <p className="mt-2"><Check /><span className="ml-1 has-tooltip-right" data-tooltip="Only on supported databases">Database transactions reset strategy*</span></p>
            </div>
        </div>
    </Section>;
}