import { useRef } from 'react';
import { Icon } from '../../Components/Icon';

export const HomeBanner = () => {
    const bannerRef = useRef<HTMLDivElement | null>(null);

    return <div className="home-banner-container" ref={bannerRef}>
        <section className="hero is-info home-banner-content">
            <div className="hero-body is-flex is-flex-direction-column">
                <h1 className="title home-banner-content-title">Ultra Fast<br />Integration Tests</h1>
                <div className="columns">
                    <div className="column is-two-fifths">
                        <p className="subtitle home-banner-content-subtitle">Accelergreat your tests.</p>
                        <p>
                            Write and execute efficient, high performance integration tests with ease using Accelergreat, a powerful dotnet integration testing solution. Accelergreat automatically provisions and manages external dependencies for your tests, such as databases and APIs. Accelergreat your integration testing development process today.
                        </p>
                        <div>
                            <h2 className="subtitle">Get Accelergreat for xUnit</h2>
                            <code>
                                <a href="https://www.nuget.org/packages/Accelergreat.Xunit" target="_blank">dotnet add package Accelergreat.Xunit</a>
                            </code>
                            <h2 className="subtitle">Read the documentation</h2>
                            <p>
                                <a href="https://docs.accelergreat.net/" target="_blank">Getting started guide</a>
                            </p>
                            <p>
                                <a href="https://docs.accelergreat.net/api/" target="_blank">Explore the API docs</a>
                            </p>
                        </div>
                    </div>
                    <div className="column is-one-fifth" />
                    <div className="column is-two-fifths">
                        <p className="subtitle home-banner-content-subtitle">Features at a glance</p>

                        <h3 className="subtitle is-feature">Fast</h3>
                        <p>Accelergreat has been designed from the ground up to focus on test execution speed.</p>

                        <h3 className="subtitle is-feature">Simple API</h3>
                        <p>Accelergreat handles a lot of the testing boilerplate behind the scenes, improving the ease and speed of your test development.</p>

                        <h3 className="subtitle is-feature">Flexible</h3>
                        <p>A wide range of scenarios and resources are supported to match as many integration testing requirements as possible.</p>

                        <h3 className="subtitle is-feature">Free</h3>
                        <p>Accelergreat is free to use so you can download it now and try it out. If you like what you see then consider <a href="pricing">upgrading to premium</a> to unlock more features such as parallel execution.</p>
                    </div>
                </div>
                <div
                    className="mt-auto mr-auto ml-auto is-clickable home-banner-content-footer"
                    onClick={() => {
                        document.scrollingElement?.scrollTo({
                            behavior: "smooth",
                            top: bannerRef.current?.scrollHeight
                        });
                    }}>
                    Explore <Icon icon="arrow-down-circle-outline" />
                </div>
            </div>
        </section>
    </div>;
};