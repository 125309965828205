export type NotificationColor =
    | "success"
    | "danger";

export type AddNotificationAction = {
    type: "ADD_NOTIFICATION",
    message: string,
    color: NotificationColor
};

export type RemoveNotificationAction = {
    type: "REMOVE_NOTIFICATION",
    id: string
};

export type NotificationActions =
    | AddNotificationAction
    | RemoveNotificationAction;

export const addNotification = (message: string, color: NotificationColor): AddNotificationAction => {
    return {
        type: "ADD_NOTIFICATION",
        message: message,
        color: color
    };
};

export const removeNotification = (id: string): RemoveNotificationAction => {
    return {
        type: "REMOVE_NOTIFICATION",
        id: id
    };
};